<template>
  <div>
    <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-lg" data-backdrop="static" data-keyboard="false">
        <div class="modal-content">
          <div class="modal-header">
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" v-on:click="closePlayer()"></button>
          </div>
          <div class="modal-body">
            <video :src="this.selected.url" autoplay loop preload="metadata" height="430" class="video-featured margin-40" v-if="this.selected.url"></video>
          </div>
          <div class="modal-footer">
            <a class="btn btn-primary" :href="this.selected.url" download target="blank">Download</a>
          </div>
        </div>
      </div>
    </div>

    <div id="player" v-if="this.selected">
    </div>
    <div id="gallery">
      <div v-for="item in this.itens" :key="item.id" id="item-{{ item.id }}" data-bs-toggle="modal" data-bs-target="#exampleModal">
        <img class="img-item" :src="item.thumbnail" v-on:click="handleSelect(item)"/>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  data() {
    return {
      selected: '',
      itens: []
    }
  },
  beforeMount() {
    axios.get('https://api.start360.startinterativa.com/api/event/gallery/' + this.$route.params.event).then(res => {
      this.itens = res.data
    })
  },
  methods: {
    handleSelect(n) {
      this.selected = n
      console.log(this.selected)
    },
    closePlayer() {
      this.selected = ''
    }
  }
}
</script>


<style scoped>
.img-item {
  width: 250px;
  margin: 5px;
  cursor: pointer;
}
#gallery {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.video-featured {
  width: 100%;
}
@media only screen and (max-width: 768px) {
  .img-item {
    width: 100%;
  }
}
</style>
